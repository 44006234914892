<template>
  <div id="tender-list">
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-user-create'"
        :title="'User list'"
        :button-text="'Create'"
        :show-action-button="false"
        :link-button-color="'success'"
      />
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('Type to search')"
            clearable
            outlined
            hide-details
            dense
            class="tender-search mb-4"
            @keyup="getDataFromApi"
            @click:clear.prevent="resetSearch()"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            :placeholder="$t('Status filter')"
            :items="statusOptions"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            class="mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="locationFilter"
            :placeholder="$t('Location filter')"
            :items="$store.getters.getStocks"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            hide-details
            class="mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedRows"
        dense
        :headers="tableColumns"
        :items="userListTable"
        :items-per-page="getItemsPerPageFromLS()"
        :options.sync="options"
        :server-items-length="totalUsers"
        :loading="loading"
        :no-results-text="$t('noResults')"
        :no-data-text="$t('noResults')"
        :footer-props="{
          'items-per-page-text': '#',
          'items-per-page-options':[10, 20, 50, 100],
          'page-text': `{0}-{1} ${$t('of')} {2}`
        }"
      >
        <template #[`item.first_name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-user-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.first_name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.last_name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-user-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.last_name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.company.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.company.name }}
            </div>
          </div>
        </template>

        <template #[`item.email`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-user-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.email }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.phone`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.phone }}</span>
          </div>
        </template>

        <template #[`item.location`]="{item}">
          {{ item.location ? getUserLocation(item) : '' }}
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            class="v-chip-light-bg font-weight-semibold text-capitalize"
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
          >
            {{ $t(item.status) }}
          </v-chip>
        </template>

        <template #[`item.user_type`]="{item}">
          <v-chip
            small
            class="v-chip font-weight-semibold text-capitalize"
          >
            {{ $t(item.user_type) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'admin-user-edit',params:{id:item.id}}"
                link
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('Edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at && item.email !== 'office@sistemcd.rs'"
                link
              >
                <v-list-item-title @click.prevent="deleteUser(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('Delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreUser(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('Restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'
import statusService from '@/views/shared/statusService'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import searchMixin from '@/mixins/searchMixin'
import { getItemsPerPageFromLS } from '@/services/localStorageService'

export default {
  components: { StandardCardHeader },
  mixins: [searchMixin],
  data() {
    const selectedRows = []
    const userListTable = []
    const totalUsers = 0
    const loading = true
    const options = {}
    const currentUser = JSON.parse(localStorage.getItem('userData'))

    const tableColumns = [
      {
        text: this.$t('First name'),
        value: 'first_name',
      },
      {
        text: this.$t('Last name'),
        value: 'last_name',
      },
      {
        text: this.$t('login.username'),
        value: 'email',
      },
      {
        text: this.$t('Phone'),
        value: 'phone',
      },
      {
        text: this.$t('Location'),
        value: 'location',
      },
      {
        text: this.$t('Status'),
        value: 'status',
      },
      {
        text: this.$t('Type'),
        value: 'user_type',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    const statusFilter = ''
    const locationFilter = ''
    const statusOptions = this.$store.getters.statusesWithTrashed

    return {
      options,
      loading,
      statusFilter,
      locationFilter,
      tableColumns,
      userListTable,
      selectedRows,
      totalUsers,
      statusOptions,
      currentUser,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    this.resolveStatusVariant = statusService.resolveStatusVariant.bind(this)
    if (!this.currentUser || this.currentUser.role === 'user') {
      this.$router.push({ name: 'admin-warrant-list' })
    }
  },

  methods: {
    getItemsPerPageFromLS,
    getUserLocation(user) {
      const location = this.$store.getters.getStocks.find(stock => stock.id === Number.parseInt(user.location, 10))

      return location ? location.name : ''
    },
    getDataFromApi() {
      localStorage.setItem('itemsPerPage', `${this.options.itemsPerPage}`)
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'id'
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : 'true'
      const search = this.searchQuery ?? ''
      const status = this.statusFilter ?? ''
      const location = this.locationFilter ?? ''
      axiosIns.get(`/admin/users?page=${this.options.page}&perPage=
      ${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&status=${status}&location=${location}`)
        .then(res => {
          this.totalUsers = res.data.users.total
          this.userListTable = res.data.users.data
          this.loading = false
        })
        .catch()
    },
    deleteUser(id) {
      axiosIns.delete(`/admin/users/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restoreUser(id) {
      axiosIns.put(`/admin/users/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>

<style lang="scss">
</style>
